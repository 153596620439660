<template>
  <div class="hello">
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :visible.sync="show"
               top="20px"
               width="1300px"
               @close="closeCall">
      <el-row v-if="show">
        <!--左  视频-->
        <el-col :span="24">
          <div class="video-wrap">
            <div id="video-box">
              <div id="self-box"/>
            </div>
            <div class="control-btn">
              <ul>

                <li :class="{playing:audioController,pause:!audioController}">
                  <span :class="{playing:audioController,pause:!audioController}" class="un"></span>
                  <i
                      class="iconfont icon-maikefeng"
                      style="font-size: 50px"
                      @click="controlAudio"/></li>
                <!--                <li><i class="iconfont icon-shexiangtou" style="font-size: 50px"/></li>-->
              </ul>
            </div>
          </div>

        </el-col>

        <!--右  说明-->
        <el-col :span="8">
          <div v-if="1==0" class="li">
            <p v-for="item in 22" :key="item" s>心理咨询内容心理咨询内容心理咨询内容心理咨询内容咨询内咨询内容心咨询内容心内咨询内容心咨询内容心内咨
              询内容心咨询内容心询内容心咨询内容心内咨询内容心咨询内容心内咨询内容心咨询内容心。</p>
          </div>
        </el-col>

      </el-row>

      <!--      <span slot="footer">-->
      <!--          <el-row justify="start" type="flex">-->
      <!--            <el-col :span="2">-->
      <!--              <el-button type="info">返回工作台</el-button>-->
      <!--            </el-col>-->
      <!--            <el-col :span="8">-->
      <!--              <el-button type="danger" @click="closeHandler">关闭通话</el-button>-->
      <!--            </el-col>-->
      <!--            <el-col v-if="1==0" :span="11">-->
      <!--              <el-button type="primary">确定</el-button>-->
      <!--            </el-col>-->
      <!--          </el-row>-->
      <!--        </span>-->

    </el-dialog>
  </div>
</template>

<script>
import HRTC from '@/SDK/hrtc';  // 华为实时音视频工具包
import * as CryptoJS from 'crypto-js';  // 加密
import service from "../utils/request";
import {mapState} from "vuex";
import Generate from '@/utils/GenerateTestUserSig'

export default {
  name: 'HelloWorld',
  props: {},
  data() {
    return {
      ...require('@/assets/app.json'),
      show: false,
      client: null, // 客户端
      // 用户角色
      // 0 => 互动观众（joiner）
      // 1 => 主播（publisher）
      // 2 => 普通观众（player）
      joinRole: 0,
      localStream: null, // 本地流对象
      audioController: true,
      recording: false,
      socket: null,
      sendObj: null
    }
  },
  computed: {
    ...mapState(['roomId', 'user'])
  },
  async created() {
    this.socket = new WebSocket(process.env.VUE_APP_SOCKET + `/imserver/customer${this.user.id}`)
    this.socket.onopen = function () {
      console.log('socket已开启');
    }
    this.socket.onmessage = this.onMessageReceived
  },
  methods: {
    onMessageReceived(res) {
      console.log(res, 'socket')
      try {
        const payload = JSON.parse(res.data)
        console.log(payload, 'socket');
        if (typeof payload === 'object' && payload.code !== 200) {
          this.$message.error(payload.message)
        } else if (payload.code == 200) {
          this.closeHandler()
        }
      } catch (e) {

      }
    },
    async open(obj) {
      this.sendObj = obj
      this.show = true
      await this.localCreateClient();

    },
    //麦克风开关
    controlAudio() {
      this.audioController = !this.audioController
      if (this.audioController) {
        this.localStream.unmuteAudio()
      } else {
        this.localStream.muteAudio()
      }
    },
    //生成TOKEN
    async creatToken() {

    },
    // 生成用户信息  加入房间
    generateUserInfo() {
      const ctimeNum = 7200000; // 单位：毫秒
      const ctime1 = Math.floor((ctimeNum + new Date().getTime()) / 1000).toString();
      const message = `${this.appId}+${this.roomId}+${String(this.user.id)}+${ctime1}`;

      return {
        userId: String(this.user.id),
        signature: CryptoJS.HmacSHA256(message, this.appSecret).toString(), // 前端加密
        ctime: ctime1,
        role: this.joinRole
      }
    },
    // 创建客户端
    async localCreateClient() {
      this.client = HRTC.createClient({
        appId: this.appId,
        domain: this.domain,
        countryCode: 'CN'
      });
      this.joinRoom()
    },

    // 加入房间
    joinRoom() {
      this.client.join(String(this.roomId), this.generateUserInfo()).then(async res => {
        this.subscribeMedia()
        this.createClientStream()
        const send = {
          roomId: this.roomId,
          userInfo: this.user,
          toUserId: 'teacher' + this.roomId,
          sendObj: this.sendObj,
          code: 201
        }
        this.socket.send(JSON.stringify(send))
      }).catch(err => {
        console.log(err)
      })
    },

    // 创建本地流并发布  播放本地视频
    async createClientStream() {
      const drivers = await navigator.mediaDevices.enumerateDevices()
      const hasAudio = drivers.filter(v => {
        return v.kind === 'audioinput'
      })
      const hasVideo = drivers.filter(v => {
        return v.kind === 'videoinput'
      })

      const stream = HRTC.createStream({audio: hasAudio.length > 0, video: hasVideo.length > 0})
      stream.initialize().then(async () => {
        stream.addResolution('90p_1')  //可选的，如果要开启双流可以添加另外一个分辨率的视频
        this.client.publish(stream)
        stream.play('self-box', {muted: false})
        this.localStream = stream
      }).catch(res => {
        // stream.play('self-box', {muted: false})   //播放本地流
      }).catch(err => {
        this.$confirm('查询设备失败，请检查麦克风或者摄像头', '警告', {
          confirmButtonText: "确定",
          showCancelButton: false,
          callback: () => {
            // this.closeCall()
          }
        })
      })
      this.localStream = stream
    },

    // 订阅远端媒体 播放远端视频
    subscribeMedia() {
      this.client.on('stream-added', async (event) => {

        const steam = event.stream
        this.client.subscribe(steam, {video: true, audio: true})

      })
      this.client.on('stream-subscribed', (event) => {
        const stream = event.stream
        stream.play('video-box', {objectFit: 'contain', muted: false})
      })
      this.client.on('peer-leave', (event) => {
        // just do something…
        // event.stream.close()
        this.client.leave();
        this.closeHandler()
      })
      this.client.on('stream-removed', (event) => {
        // event.stream.close()
        // this.closeCall()
      })
    },
    closeHandler() {
      this.$confirm('咨询是否已经结束？', '提示', {
        distinguishCancelAndClose: true,

      }).then(() => {
        this.closeCall()
        this.$emit('success')
      }).catch(action => {

      })
    },
    // 关闭通话
    closeCall() {
      // this.client.on('peer-leave', (event) => {
      //   console.log('触发了1')
      //   // just do something…
      //   event.stream.close()
      // })
      // this.client.on('stream-removed', (event) => {
      //   console.log('触发了2')
      //   event.stream.close()
      // })
      this.client.leave();
      this.show = false
      this.localStream.close()
      this.localStream = null
      // setTimeout(() => {
      //   location.reload()
      // }, 1000)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.control-btn {
  margin-left: 10px;
  float: left;
  width: 70px;
  height: 300px;

  li {
    width: 60px;
    height: 50px;
    border: 1px solid #373737;
    border-radius: 5px;
    margin-top: 10px;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.video-wrap {
  overflow: hidden;
  padding: 15px 20px 10px 10px;
  background: #000000;
}

#video-box {
  float: left;
  position: relative;
  width: calc(100% - 80px);
  height: 500px;
  background: tomato;
  border-radius: 10px;
}

#self-box {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 200px;
  background: pink;
  z-index: 999;
  border-radius: 10px;
}

.li {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  padding: 30px;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #909399;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #131313;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #434343;
  }
}

.iconfont {
  &:active {
    opacity: .78;
  }

}

.pause {
  color: $danger-color;
}

.playing {
  color: $success-color;
}

.un {
  display: inline-block;
  width: 4px;
  height: 50px;
  position: absolute;
  transform: rotateZ(45deg);
  border-radius: 2px;
  transition: all .15s linear;

  &.pause {
    background-color: $danger-color;
    left: 50%;
    bottom: 0;
    transform: rotateZ(45deg) translateX(-50%);
  }

  &.playing {
    right: -25px;
    top: -50px;
    background-color: $success-color;
  }
}
</style>
