import Vue from 'vue';
import Vuex from 'vuex';
import { getValue, setValue, removeKey } from "../utils";
import { getUserInfo } from "../api/home";
import {Message} from "element-ui";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null, // 用户信息
    token: getValue('ACCESS_TOKEN',) ? getValue('ACCESS_TOKEN',) : '',
    isModify: false, // 修改密码弹窗
    evaluateShow: false, // 评价弹窗
    contentId: null, // 内容id
    id: null, // 咨询师id
    serviceScore: null, // 星星数量
    comment: '', // 评价内容
    featuredShow: false, // 精选侧边栏弹窗
    featuredDetails: null, // 首页侧边栏精选咨询师详情
    loginShow: false, // 登录弹框
    roomId: '', // 视频房间id
    userId: '', // 用户id
    three: '' // 1是自身咨询，2是三方咨询
  },
  mutations: {
    // 开启修改密码弹窗
    changeModify(state) {
      state.isModify = true
    },

    // 关闭修改密码弹窗
    closeModifyPopup(state) {
      state.isModify = false
    },

    // 开启评价弹窗
    openEvaluate(state, payLoad) {
      state.evaluateShow = true;
      state.contentId = payLoad.contentId;
      state.id = payLoad.id;
      state.serviceScore = payLoad.serviceScore;
      state.comment = payLoad.comment;
      state.three = payLoad.three;
    },

    // 关闭评价弹窗
    closeEvaluate(state) {
      state.evaluateShow = false;
    },

    // 开启精选咨询师详情侧边栏
    openFeaturedSidebar(state, payLoad) {
      console.log('1拿到的值:', payLoad)
      state.featuredShow = true;
      state.featuredDetails = payLoad;
    },

    // 关闭精选咨询师详情侧边栏
    closeFeaturedSidebar(state) {
      state.featuredShow = false;
    },

    // 存入token
    setToken(state, payLoad) {
      state.token = payLoad;
      setValue('ACCESS_TOKEN', payLoad)
    },

    // 清空token
    removeToken(state) {
      state.token = '';
      removeKey('ACCESS_TOKEN');
    },

    // 展示登录弹框
    showLoginPopup(state) {
      state.loginShow = true
    },

    // 关闭登录弹框
    closeLoginPopup(state) {
      state.loginShow = false
    },

    // 随机生成一个房间号和用户id
    randomRoomIdOruserId(state) {
      // state.roomId = Math.floor(Math.random() * 100);

    },
    SET_ROOM(state,room){
      state.roomId = room
    },
    // 将用户信息存入状态管理
    setUserInfo(state, payLoad) {
      state.user = payLoad;
    }
  },
  actions: {
    handleUserInfo({ commit }) {
      getUserInfo().then(res => {
        commit('setUserInfo', res.data)
      }).catch(err=>{
        Message({
          type:"error",
          message:"用户名或者密码错误"
        })
        commit('removeToken')
        commit('showLoginPopup')
      })
    }
  },
  modules: {
  }
})
