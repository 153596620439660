// 加盐
export const formatPwd = function(pwd) {
  const salt = 'psy-api-2021';
  let str = salt.charAt(0) + salt.charAt(3) + pwd + salt.charAt(5) + salt;
  return md5(str).toUpperCase();
}

// String str = String.valueOf(salt.charAt(0)) + String.valueOf(salt.charAt(3)) + pwd + String.valueOf(salt.charAt(5)) + salt;

// 本地存
export const setValue = (key, value) => {
  localStorage.setItem(key, value)
}

// 本地取
export const getValue = (key) => {
  return localStorage.getItem(key)
}

// 清除本地key
export const removeKey = (key) => {
  localStorage.removeItem(key)
}
