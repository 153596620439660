import request from "../utils/request";

// 登录
export const goLogin = params => request({ url: '/oauth/token', method: 'POST', params });

// 获取精选咨询师
export const getFeaturedCounselor = () => request({ url: '/cs/teacher/list-teacher', method: 'GET' });

// 获取精选咨询
export const  getFeaturedCounsel = () => request({ url: '/cs/content/featured', method: 'GET' });

// 修改密码
export const restPassword = data => request({ url: '/admin/change-pwd', method: 'POST', data });

// 获取登录用户详细信息
export const getUserInfo = () => request({ url: '/cs/customer/get-customer', method: 'GET' });
