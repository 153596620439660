<template>
  <div class="user">
    <el-avatar :src="user && user.avatar" />
    <el-dropdown @command="command">
      <span class="el-dropdown-link">
        {{ user && user.name }}<i class="el-icon-arrow-down el-icon--right" />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
        <el-dropdown-item command="modifyPassword">修改密码</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
export default {
  name: "user",
  computed: {
    ...mapState(['user'])
  },
  methods: {
    ...mapMutations(['changeModify', 'removeToken']),
    command(e) {
      if (e === 'loginout') {
        // 清除token
        this.removeToken()

        this.$router.push({path: '/home'})
      } else if (e === 'modifyPassword') {
        this.changeModify()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.user {
  height: 100%;
  display: flex;
  align-items: center;

  .el-dropdown {
    margin-left: 10px;
    cursor: pointer;
  }
}
</style>
