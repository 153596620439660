import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import layout from "../layout/layout";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'layout',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import('../layout/layout.vue'),
        children: [
            {
                path: '/',
                name: '首页',
                component: () => import('../views/home.vue'),
                props: {
                    icon: require('@/assets/home@2x.png'),
                    iconSelected: require('@/assets/homeSelected@2x.png'),

                },

            },
            {
                path: '/service',
                name: '咨询服务',
                component: () => import('../views/AdvisoryServices.vue'),
                props: {
                    icon: require('@/assets/counselService@2x.png'),
                    iconSelected: require('@/assets/counselServiceSelected@2x.png'),

                },
                children: [
                    {
                        path: '/consultDetails',
                        name: '咨询详情',
                        component: () => import('../views/ConsultingDetails.vue'),

                    }
                ]
            },
            {
                path: '/panel',
                name: '个人面板',
                component: () => import('../views/IndividualPanel.vue'),
                props: {
                    icon: require('@/assets/profile@2x.png'),
                    iconSelected: require('@/assets/profileSelected@2x.png'),
                },
            },
        ]
    },
    {
        path: '/login',
        name: '登录',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
    },
    {
        path: '/home',
        name: '首页',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@views/home.vue')
    },
    {
        path: '/outside-video',
        name: '首页',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '@views/outside-video.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// 导航守卫  判断用户是否登录
router.beforeEach((to, from, next) => {
    if (to.path === '/home' || to.path === '/outside-video') {
        next();
    } else {

        if (store.state.token) {
            next();
        } else {
            next('/home')
        }

    }
})


export default router
