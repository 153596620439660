<template>
  <ul>
    <!-- <li class="logo">咨询系统&nbsp;&nbsp;用户端</li> -->
    <router-link
      v-for="(route, index) in $router.options.routes[0].children"
      :key="route.path"
      :class="{ active: route.path === $route.path || ($route.path === '/consultDetails' && index === 1) }"
      :to="{ path: route.path }"
      tag="li">
      <img v-if="route.path === $route.path || ($route.path === '/consultDetails' && index === 1)" class="nav-icon-selected"  :src="route.props.iconSelected" alt="0" />
      <img v-else class="nav-icon"  :src="route.props.icon" alt="0" />
      <span>{{ route.name }}</span>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "navigation",
  created() {
    console.log('左侧路由：', this.$route)
  }
}
</script>

<style lang="scss" scoped>
ul {
  width: 100%;

  li {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    i {
      font-size: 26px;
      margin-bottom: 5px;
    }

    &:not(.logo) {
      cursor: pointer;
      color: #434444;
      font-size: 16px;
      height: 100px;
      transition: all .3s linear;
      &.active{
        color: $theme-color;
      }
    }

    &.logo {
      height: 85px;
      background: $theme-color;
      font-size: 23px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}

.nav-icon,
.nav-icon-selected {
  width: 27px;
  height: 27px;
  margin-bottom: 5px;
}
</style>
