<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
export default {
  name: 'App',
  created() {
    if (this.token) {
      this.handleUserInfo();
    }
  },
  mounted() {
  },
  computed: {
    ...mapState(['token'])
  },
  methods: {
    ...mapActions(['handleUserInfo']),
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

</style>
