<template>
  <div>
    <el-dialog
        title="修改密码"
        :visible="isModify"
        width="30%"
        center
        @close="closeModifyPopup">
      <el-form>
        <!--输入新密码-->
        <el-form-item>
          <el-input v-model="form.oldPassWord" type="password" placeholder="请输入旧密码" />
        </el-form-item>
        <!--确认新密码-->
        <el-form-item>
          <el-input v-model="form.loginPwd" type="password" placeholder="请输入新密码" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!--<el-button @click="closeModifyPopup">取 消</el-button>-->
        <el-button type="primary" @click="handleResetPassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { restPassword } from "@/api/home";
import { formatPwd } from '@utils';
import { Message } from 'element-ui';

export default {
  data() {
    return {
      centerDialogVisible: false,
      form: {
        oldPassWord: '',
        loginPwd: ''
      }
    }
  },
  computed: {
    ...mapState(['isModify'])
  },
  methods: {
    ...mapMutations(['closeModifyPopup']),
    // 开启修改密码弹窗
    modifyHandle() {
      this.centerDialogVisible = true
    },

    // 修改密码
    handleResetPassword() {
      const params = { ...this.form };

      // 新旧密码加盐
      params.oldPassWord = formatPwd(params.oldPassWord);
      params.loginPwd = formatPwd(params.loginPwd);

      restPassword(params).then(res => {
        console.log('修改密码1:', res)

          // 关闭修改密码弹窗
          if (res.code === 200) {
            this.closeModifyPopup();
            Message({
              type: 'success',
              message: '修改密码成功'
            });
          }

      }).catch(reason => {
        console.log('修改密码2:', reason)
        Message({
          type: 'error',
          message: '旧密码错误'
        });

      })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  width: 542px !important;
}

/deep/ .el-input__inner {
  background: #FBFBFB;
}
</style>
