import axios from "axios";
import {showLoading, hideLoading} from "@utils/Loading";
import store from "@/store";
import { Message } from "element-ui";
import router from "../router";

const UAA = process.env.VUE_APP_UAA;
const CONSULT = process.env.VUE_APP_CONSULT;

const baseURL =
    process.env.NODE_ENV === "production" ? process.env.VUE_APP_API : "";
const service = axios.create({
    baseURL,
    timeout: 10000,
});

// 请求拦截
service.interceptors.request.use(
    (config) => {
        const environment = process.env.NODE_ENV;
        const {token} = store.state;

        // 是否存在token
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }

        // 判断当前环境 区分接口  加上前缀
        if (environment === "production") {
            if (config.url.indexOf("cs") !== -1) {
                config.url = CONSULT + config.url;
            } else if (config.url.indexOf("admin") !== -1) {
                config.url = CONSULT + config.url;
            } else if (config.url.indexOf("oauth") !== -1) {
                config.url = UAA + config.url;
            }
        }

        // 开启Loading
        // showLoading();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截
service.interceptors.response.use(
    (response) => {
        if (response.data.code === 500) {
            return Promise.reject(response.data);
        }
        // token过期
        if (response.data.error && response.data.error.code === 100010) {
            store.commit("removeToken");
            Message({
                showClose: true,
                message: response.data.error.message,
                type: "error",
            });
            // 跳转到登录页面
          router.push('/login')
        }

        // 关闭Loading
        // hideLoading();
        return response.data;
    },
    (error) => {
        const {
            response: {
                data: {code, msg},
            },
        } = error;

        if (code === 500) {
            Message({
                showClose: true,
                message: msg,
                type: "error",
            });
        }

        return Promise.reject(error);
    }
);

export default service;
