<template>
  <div>
    <el-container v-if="$route.path !== '/login'">
      <!-- <el-aside :width="token?'250px':'0px'">
        <Navigation/>
      </el-aside> -->
      <div :class="{all:!token}" class="main-wrap">
        <!-- <el-header v-if="token" height="85px">
          <user/>
        </el-header> -->
        <el-main>
          <transition mode="out-in" name="fade">
            <router-view class="view-box"></router-view>
          </transition>
        </el-main>
        <!-- <el-footer>
          <el-link class='company_link'
                   href='http://xinli121.com/' target='_blank'>技术服务支持 @{{ (new Date()).getFullYear() }} 辰微沐阳
          </el-link>
        </el-footer> -->
      </div>
    </el-container>

    <!--修改密码弹出框-->
    <ModifyPassword ref="modify"/>
  </div>
</template>

<script>
import Navigation from './components/navigation';
import user from "./components/user";
import ModifyPassword from "./components/ModifyPassword";
import {mapState} from 'vuex'

export default {
  components: {
    Navigation,
    user,
    ModifyPassword
  },
  name: "layout",
  methods: {
    modifyHandler() {
      this.$refs.modify.modifyHandle()
    }
  },
  computed: {
    ...mapState(['token'])
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  // background: rgb(246, 246, 246);
  // height: 100vh;
  // width: 100vw;
  overflow: hidden;

  .el-aside, .el-header {
    background: white;
  }

  .el-header {
    display: flex;
    justify-content: flex-end;
  }

  .main-wrap {
    width: calc(100vw - 250px);
    &.all{
      width: 100%;
    }
  }

  .el-main {
    // height: 100%;
    position: fixed;
    right: 10px;
    bottom: 20px;
    box-sizing: border-box;

    // background-color: #000;
    overflow: hidden;
    width: 25%;
    //padding-bottom: 20px;
    &::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #909399;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #131313;
      border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #434343;
    }
  }

  .view-box {
    min-height: 100%;
    width: 100%;

  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.company_link {
  //transform: translateX(-50%);
}

.el-footer {
  text-align: center;
  width: 15%;
  height: 40px !important;
  box-sizing: border-box;
}
</style>
